//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from "./networking/api";
import {  Toast } from "vant";
export default {
  name: "Detail",
  data() {
    return {
      recordInfo: {
        claimStatus: 0,
      },
      audio: "",
      timer: null,
      loading: false,
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getActInfo();
  },
  beforeDestroy() {
    // 在组件销毁前清除定时器
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },
  methods: {
    goList() {
      this.$router.push({
        path: "/incentiveRedpacket/index",
        query: {
          storeId: this.recordInfo.storeId,
          actionId: this.recordInfo.actionId,
        },
      });
    },
    getActInfo() {
      const that = this;
      this.axios
        .get(api.getIncentiveRedpacketRecordById, {
          params: {
            id:
              that.$route.query.recordId,
            //  bpid: "1000898493",
          },
        })
        .then((res) => {
          console.log("getActInfo", res.data);
          if (res.data && res.data.code == 0) {
            that.recordInfo = res.data.data || {};
          } else {
            this.$dialog.alert({
              message: res.data.msg,
            });
          }
        });
    },
    receiveGift() {
      const that = this;
      // Dialog.confirm({
      //  title: '确认领取？',
      // })
      //   .then(() => {
      if (!that.recordInfo.storeId) {
        this.$dialog.alert({
              message: "红包领取异常，请联系管理员",
            });
        return;
      }
      if (that.loading) {
        this.$toast({ message: "请勿重复点击", duration: 2000 });
        return;
      }
      that.loading = true;
      Toast.loading({
        message: "正在领取中...",
        duration: 0,
        mask:true
      });
      this.axios
        .get(api.receiveIncentiveRedPacket, {
          params: {
            storeId: that.recordInfo.storeId,
            id: that.recordInfo.id,
          },
        })
        .then((res) => {
          Toast.clear();
          that.loading = false;
          if (res.data && res.data.code == 0) {
            that.audio = this.$refs.bgaudio;
            that.audio.play();
            // 设置定时器，三秒后停止播放
            that.timer = setTimeout(() => {
              console.log(this.audio);
              if (this.audio) {
                this.audio.pause();
                this.audio.currentTime = 0; // 重置音频时间
              }
              that.recordInfo.claimStatus = 1;
            }, 2000);
          } else {
            that.$dialog.alert({
              message: res.data.msg,
            });
          }
        });
      // }).catch(() => {})
    },
  },
};
